import React, { useState } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { motion, useMotionValue, useTransform } from 'framer-motion/dist/framer-motion'


const App = () => {
  const [poelse, setPoelse] = useState(null)
  const [dressings, setDressings] = useState(null)

  const getName = () => {
    if (!dressings || !poelse) return ''

    let nationality
    let character

    switch (poelse) {
      case 'Jalapeño og cheddar pølse':
        nationality = 'Mexicaner'
        break

      case 'Chilipølse':
        nationality = 'Brian Nielsen'
        break

      case 'Ostepølse':
        nationality = 'Schweitzer'
        break

      case 'Frankfurter':
        nationality = 'Tysker'
        break

      case 'Vildsvinepølse':
        nationality = 'Polak'
        break

      case 'Medister':
        nationality = 'Flemming Bamse'
        break

      case 'Pølse i svøb':
        nationality = 'Baron'
        break

      case 'Grillpølse':
        nationality = 'Tyroler'
        break

      case 'Krasser':
        nationality = 'Uldsok'
        break

      default:
        nationality = poelse
    }

    character = dressings
      .map(d => {
        switch (d) {
          case 'Fransk dressing':
            return 'alpehue'
          case 'Ketchup':
            return 'klaphat'
          case 'Chili kethcup':
            return 'sombrero'
          case 'Karry ketchup':
            return 'turban'
          case 'Remoulade':
            return 'sydvest'
          case 'Sennep':
            return 'uldhue'
          case 'Chili sauce':
            return 'sombrero'
          case 'Krydderdressing':
            return 'michelin (smart-sauce)'
          case 'Dijondressing':
              return 'Macron-dyp'
          default:
            return d
        }
      })
      .join(' og ')

    // custom combinations
    if (
      poelse === 'Chilipølse' &&
      dressings.filter(f => f === 'Karry ketchup').length > 1
    ) {
      return 'Dobbelt Bombay'
    } else if (poelse === 'Chilipølse' && dressings.includes('Karry ketchup')) {
      return 'Bombay'
    }

    return nationality + ' med ' + character
  }

  const generate = () => {
    const numberOfDressings = Math.abs(Math.random() * 2)

    const dressings = []

    const poelse = getPoelse()

    while (dressings.length < numberOfDressings) {
      dressings.push(getDressing())
    }

    setPoelse(poelse)
    setDressings(dressings)
  }


  let isTheSameDressing = false
  if (dressings && dressings[0] === dressings[1]) {
    isTheSameDressing = true
  }

  const name = getName()

  const y = useMotionValue(0)
  const rotate = useTransform(y, [0, 100, 200], [0, -100, -100])
  const x = useTransform(y, [0, 40], [0, -20])

  return (
    <Wrapper>
      <Inner>
        <SlotMachine>
          <Label>← Træk i pølsearmen</Label>
          <Handle>
            <HandleInner
              style={{ rotate, y, x }}
              onDragEnd={(e, info) => {if(info.offset.y > 30) {generate()}}}
              drag="y"
              dragConstraints={{ top: 0, bottom: 50 }}
              dragSnapToOrigin
              dragElastic={{ top: 0, bottom: 0.1}}
            >
              <img src="/sausage.png" alt="Sausage" draggable={false}/>
            </HandleInner>
          </Handle>
        </SlotMachine>
        <Result>
          <h1>{name}</h1>
          {poelse && dressings && (
            <h2>
              ({poelse} med{' '}
              {isTheSameDressing
                ? `meget ${dressings[0]}`
                : dressings.join(' og ')}
              )
            </h2>
          )}
        </Result>
      </Inner>
    </Wrapper>
  )
}

  const getDressing = () => {
    const dressings = [
      'Fransk dressing',
      'Ketchup',
      'Chili kethcup',
      'Karry ketchup',
      'Remoulade',
      'Sennep',
      'Chili sauce',
      'Krydderdressing',
      'Dijondressing'
    ]
    return dressings[Math.floor(Math.random() * dressings.length)]
  }

  const getPoelse = () => {
    const poelser = [
      'Jalapeño og cheddar pølse',
      'Chilipølse',
      'Ostepølse',
      'Frankfurter',
      'Medister',
      'Pølse i svøb',
      'Grillpølse',
      'Krasser',
      'Jalapeño og cheddar pølse',
      'Chilipølse',
      'Ostepølse',
      'Frankfurter',
      'Medister',
      'Pølse i svøb',
      'Grillpølse'
    ]

    return poelser[Math.floor(Math.random() * poelser.length)]
  }

export default App

const Wrapper = styled.div`
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 30px;
  justify-content: center;
  text-align: center;
  user-select: none;

  font-weight: 200;
  box-sizing: border-box;
`

const Inner = styled.div``

const SlotMachine = styled.div`
  position: relative;
  width: 100px;
  margin: auto;
  margin-bottom: 50px;
  `

const HandleInner = styled(motion.div)`
  position: absolute;
  top: -50px;
  left: -90px;
  width: 100px;;

  /* transform-origin: bottom right; */
  /* transition: 1s; */
  `

const Handle = styled(motion.div)`
  width: 100px;
  height: 100px;
  color: #fff;
  margin-left: 0px;
  background-image: url('/slotmachine.png');
  background-size: contain;

  img {
    pointer-events: none;
    width: 100%;
  }

  /* &:active {
    ${HandleInner} {
      transform: translateX(0px) translateY(0px) rotate(-90deg);
    }
  } */
`


const Label = styled.div`
  position: absolute;
  top: -80px;
  left: 50px;
  transform: rotate(-30deg);
  text-transform: uppercase;
`

const Result = styled.div`
  min-height: 100px;

  h1 {
    ${media.xs`
      font-size: 16px;
    `}
  }

  h2 {
    ${media.xs`
      font-size: 14px;
    `}
  }
`